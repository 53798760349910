<template>
    <Card dis-hover>
        <div class="form_item">
            <div class="form_label must_input align_self_start">展示图</div>
            <div>
                <uploadImage :width="100" :height="100" v-model="formData.icon"></uploadImage>
                <div class="tip_txt">推荐尺寸： 683 x 230，高度可适当加减，宽度最好保持不变</div>
            </div>
        </div>

        <div class="form_item">
            <div class="form_label must_input align_self_start">类型</div>
            <RadioGroup v-model="formData.home_gd_type" type="button" button-style="solid">
                <Radio label="1">小程序内页</Radio>
                <Radio label="2">H5链接</Radio>
            </RadioGroup>
        </div>

        <div class="form_item">
            <div class="form_label must_input align_self_start">跳转链接</div>
            <Input class="width_500" type="text" v-model.trim="formData.home_gd_url"></Input>
        </div>

        <div class="form_item">
            <div class="form_label must_input align_self_start">显示时间</div>
            <myDatetimerange v-model="showTimeArr"></myDatetimerange>
        </div>

        <div class="form_item">
            <div class="form_label align_self_start">是否显示</div>
            <RadioGroup v-model="formData.status" type="button" button-style="solid">
                <Radio label="1">是</Radio>
                <Radio label="0">否</Radio>
            </RadioGroup>
        </div>

        <div class="mt_50 flex align_center justify_center form_item">
            <Button type="primary" ghost @click="onCloseEdit">取消</Button>
            <Button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</Button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import uploadImage from '@/components/uploadImage';
import myDatetimerange from '@/components/myDatetimerange';
import { reqSaveHomeGd } from '@/lib/request/auth2';
import { createId, getParamType, type_date } from '@/lib/util';
export default {
    name: 'homeGdAdd',
    components: {
        uploadImage,
        myDatetimerange,
    },
    data() {
        return {
            title: '配置信息',
            formData: {
                id: null,
                icon: null,
                home_gd_type: '1', //类型 1 小程序内页 2 H5链接
                home_gd_url: null,
                sorts: null,
                status: '1',
                showTime: '',
            },
            isEdit: false,
            key: createId(),
            showTimeArr: {
                start: '',
                end: '',
            },
        };
    },
    mounted() {
        this.routeParamsInjectFormData();
        this.title = this.isEdit ? '编辑' : '添加';
        if (this.isEdit) {
            let [start, end] = (this.formData.showTime || ',').split(',');
            this.showTimeArr.start = new Date(start);
            this.showTimeArr.end = new Date(end);
            console.log(this.formData.showTime, this.showTimeArr);
        }
    },
    watch: {
        //
    },
    methods: {
        onClearGoods() {
            this.goodsList = JSON.parse(JSON.stringify(this.baseGoodsList));
        },
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { icon, home_gd_url } = params || {};
            let vs = [
                { value: icon, tip: '请选择图片' },
                { value: home_gd_url, tip: '请输入跳转链接' },
            ];

            // if (1 == 1) {
            //     console.log(JSON.stringify(this.formData, null, '\t'), this.showTimeArr);
            //     this.hideAjaxLoading();
            //     return;
            // }

            hm.validateForm(vs)
                .then(() => {
                    console.log('this.showTimeArr', this.showTimeArr);
                    if (!!this.showTimeArr.start && !!this.showTimeArr.end) {
                        let { start, end } = this.showTimeArr || {};
                        if (getParamType(start) === type_date) {
                            start = start.Format('yyyy-MM-dd HH:mm:ss');
                            end = end.Format('yyyy-MM-dd HH:mm:ss');
                        }
                        params.showTime = `${start},${end}`;
                    } else {
                        this.fadeWarning('请选择显示时间');
                        this.hideAjaxLoading();
                        return;
                    }

                    reqSaveHomeGd(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.onCloseEdit();
                        })
                        .finally(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped></style>
