<template>
    <div class="relative upload">
        <Progress v-if="progress.isShow" :percent="progress.value" hide-info></Progress>
        <Upload
            class="block relative upload"
            ref="upload"
            :disabled="isDisabled"
            :show-upload-list="false"
            :max-size="maxSize"
            :format="format"
            type="drag"
            :action="action"
            :data="formData"
            :style="{ width: width + 'px', height: height + 'px' }"
            :on-success="handleSuccess"
            :on-error="handleError"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :before-upload="handleBeforeUpload"
            :on-progress="onProgress"
        >
            <template v-if="srcValue">
                <img class="pervSrc" :src="srcValue" :style="{ width: width + 'px', height: height + 'px' }" />
                <div class="upload-cover flex align_center justify_center" v-if="manager">
                    <Icon type="ios-eye-outline" @click.stop="handleView()"></Icon>
                    <Icon v-if="!isDisabled" type="ios-trash-outline" @click.stop="handleRemove()"></Icon>
                </div>
            </template>
            <div v-else :style="{ width: width + 'px', height: height + 'px', lineHeight: height + 'px' }">
                <Icon type="ios-add" size="27"></Icon>
            </div>
            <div v-if="!!!srcValue" class="limit_tip">单文件不能超过{{ Math.ceil(this.maxSize / 1024) }}M</div>
        </Upload>
        <Modal draggable title="图片预览" v-model="visible" :width="600" class="relative">
            <div style="margin: 0 auto; overflow: auto; width: 100%; height: 500px">
                <img :src="srcValue" />
            </div>
        </Modal>
    </div>
</template>
<script>
// import { uploadToken } from '@/lib/request/upload';

export default {
    name: 'uploadImage',
    props: {
        value: null,
        width: {
            type: Number,
            required: false,
            default: 200,
        },
        height: {
            type: Number,
            required: false,
            default: 200,
        },
        manager: {
            type: Boolean,
            required: false,
            default: true,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            maxSize: 2048, //单位 kb
            format: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'],
            action: window.location.origin + '/commonUpload/ajax_up',
            formData: {
                upfun: 'admin',
            },
            srcValue: null,
            dir: 'qilang',
            fileName: null,
            visible: false,
            progress: {
                isShow: false,
                value: 0,
            },
        };
    },
    watch: {
        value(newValue) {
            this.srcValue = newValue;
        },
    },
    mounted() {
        this.srcValue = this.value;
        if (process.env.NODE_ENV === 'development') {
            this.action = window.location.origin + '/upload/commonUpload/ajax_up';
        }
        // this.reqUploadToken();
    },
    methods: {
        reqUploadToken() {
            this.ajaxLoading = true;
            uploadToken().then(res => {
                // console.log('reqUploadToken response res = ', res);
                this.ajaxLoading = false;
                let { session_token, tmp_secret_id, tmp_secret_key } = res.data || {};
                let authorization = window.CosAuth({
                    SecretId: tmp_secret_id,
                    SecretKey: tmp_secret_key,
                    Method: 'POST',
                    Pathname: '/',
                });
                this.formData['Signature'] = authorization;
                this.formData['x-cos-security-token'] = session_token;
            });
        },
        handleView() {
            this.visible = true;
        },
        handleRemove() {
            this.srcValue = null;
            this.$emit('input', null);
            this.$emit('remove');
        },
        handleSuccess(res, file) {
            console.log(res,'res')
            this.progress.isShow = false;
            // let src = `${this.action}/${this.dir}/${this.fileName}`;
            let src = (res.split(',') || [])[1] || null;

            this.srcValue = src;
            console.log('handleSuccess', src);
            this.$emit('input', src);
            this.$emit('success');
        },
        handleError(error, file) {
            this.srcValue = null;
            this.progress.isShow = false;
            this.fadeWarning('上传异常');
            // this.reqUploadToken();
        },
        handleFormatError(file) {
            this.progress.isShow = false;
            this.fadeWarning(`文件格式必须为${this.format.join('、')}`);
        },
        handleMaxSize() {
            this.progress.isShow = false;
            this.fadeWarning(`文件大小不能超过${Math.ceil(this.maxSize / 1024)}M`);
        },
        handleBeforeUpload(evt) {
            this.progress.isShow = true;
            this.progress.value = 0;
            let name = evt.name;
            let [prevName, diffName] = name.split('.');
            this.fileName = `${prevName}_${hm.getTime()}.${diffName}`;
            this.formData['key'] = `${this.dir}/${this.fileName}`;
            return true;
        },
        onProgress(event) {
            this.progress.value = event.percent;
        },
    },
};
</script>
<style scoped lang="less">
.upload {
    width: 100%;
    height: 100%;
    .limit_tip {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
        text-align: center;
        font-size: 9px;
        color: rgba(0, 0, 0, 0.6);
    }
}
.upload /deep/ .ivu-upload-drag {
    width: 100%;
    height: 100%;
}

.upload-cover {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}
.upload:hover .upload-cover {
    opacity: 1;
}
.upload-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
}

.pervSrc {
    object-fit: contain;
}
</style>
